import React from 'react';
import Layout from '../../components/layout';
import withBg from '../../helpers/withBg';
import SEO from '../../components/seo';
import GroupPageSearchForm from '../../components/GroupPageSearchForm/GroupPageSearchForm';
import GroupTravelProgram from '../../components/GroupTravelProgram/GroupTravelProgram';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import { formatPhone } from '../../helpers/callTracking';

import './group-page.css';

const data = {
  title: 'Corporate Travel',
  seoTitle: 'Corporate Travel | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class CorporateTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '18442131328'
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;
    const groupTravelView = true;

    return (
      <Layout
        isStaticPage
        staticPhoneNumber={phoneNumber}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="group-container">
          <div className="top-banner">
            <div className="text-block wrapper">
              <h1>Book Group Travel Package</h1>
              <p>
                Looking to travel with a group of 5 or more, you would want to
                contact our group reservation desk as our Group Travel Program
                is for you! The program provides discounted airfare and Groups
                receive access to special discounts and offer extra flexibility
                and to help make your travel experience affordable, simple, and
                fun. No group is too small or too big for us.
              </p>
              <p>
                We have successfully organized thousands of group travels
                ranging from 5–1700 travellers. Our high volumes and
                long-standing relationship with airlines allows us to offer
                discounted and specially discounted fares for our travellers.
              </p>
            </div>
          </div>
          <GroupPageSearchForm
            phoneNumber={phoneNumber}
            groupTravelView={groupTravelView}
          />
          <div className="why-book-block text-block wrapper">
            <h2>Why Book Group Travel with Us?</h2>
            <div className="benefits-list">
              <ul>
                <li>
                  Special exclusive group fares to more than 500 destinations
                </li>
                <li>
                  Greater bundled savings upon booking flights+hotels+cars
                </li>
                <li>Even more discounts for larger groups</li>
                <li>No extra fee</li>
              </ul>
              <ul>
                <li>Flexible Payments</li>
                <li>Name changes</li>
                <li>Seat assignment for the entire group</li>
                <li>
                  Flight reservation up to 11 months in advance with a deposit
                </li>
                <li>Special dedicated group desk support</li>
              </ul>
            </div>
          </div>
          <div className="call-us-block">
            <div className="call-us-info">
              <a
                className="phone-number"
                href={formatPhone(phoneNumber, 'callAction')}
                id="call-us-phone-group-travel-icon"
              >
                <div className="call-us-icon d-flex dial-icon" />
              </a>
              <div className="call-us-text">
                <p>Book your group travel package with Travelopod today! </p>
                <a
                  id="call-us-phone-group-travel"
                  className="phone-number"
                  href={formatPhone(phoneNumber, 'callAction')}
                >
                  {formatPhone(phoneNumber)}
                </a>
              </div>
            </div>
          </div>
          <GroupTravelProgram />
          <TrustpilotHorizontal />
        </div>
      </Layout>
    );
  }
}

export default withBg(CorporateTravel);
